import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Img from "gatsby-image"

const Header = ({ siteTitle, siteDescription, siteImg }) => (
  <header
    style={{
      background: `black`,
      position: `fixed`,
      width: `100%`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `.6rem 1rem`,
      }}
    >
      <Link to="/">
        <Img
          fixed={siteImg}
          style={{
            float: `left`,
            marginRight: `1rem`,
          }}
        />
      </Link>
      <h1
        style={{
          margin: 0,
        }}
      >
        <Link
          to="/"
          style={{
            color: `white`,
            textDecoration: `none`,
          }}
        >
          {siteTitle}
        </Link>
      </h1>
      <div
        style={{
          fontSize: `1rem`,
          color: `#999`,
          fontWeight: `400`,
        }}
      >
        {siteDescription}
      </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
  siteDescription: PropTypes.string,
  siteImg: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
  siteDescription: ``,
  siteImg: ``,
}

export default Header
