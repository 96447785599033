import React from "react"
import { Link } from "gatsby"

const Footer = () => (
  <footer
    style={{
      background: `black`,
      color: `#999`,
      fontSize: `.8em`,
      position: `fixed`,
      width: `100%`,
      bottom: `0`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        padding: `.5rem 0`,
        maxWidth: 960,
      }}
    >
      © {new Date().getFullYear()} - <Link to="/mose">mose</Link> - Généré avec
      {` `}
      <a style={{ color: `#369`}} href="https://www.gatsbyjs.org">Gatsby</a> chez
      {` `}
      <a style={{ color: `#369`}} href="https://gitlab.com/ozmose/blog-fr">Gitlab</a>
    </div>
   </footer>
)

export default Footer
